import React from 'react'
import Slider from 'react-rangeslider'

const RangeSlider = ({ value, onChange }) => {
    return (
        <Slider
            value={value}
            min={0}
            max={70}
            step={1}
            labels={{ 1: '1', 10: '10', 20: '20', 30: '30', 40: '40', 50: '50', 60: '60', 70: '70+' }}
            tooltip={true}
            onChange={onChange}
        />
    )
}

export default RangeSlider;