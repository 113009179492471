import React, { useState } from "react"

import { graphql } from "gatsby"

import Layout from "../components/layout"
import BannerIntro from "../components/BannerIntro"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import Divider from "../components/Divider"
import Button from "../components/Button"
import Seo from "../components/Seo"
import RangeSlider from "../components/RangeSlider"
import { GatsbyImage } from "gatsby-plugin-image"

const CovidTesting = (props) => {
  const pageData = props.data.wpPage
  const seoData = props.data.wpPage.seo

  const [setChecked] = useState(false)
  const [volume, setVolume] = useState(1)

  const determinePrice = (pricing) => {
    const price = pricing.find(({ lowestNumber, highestNumber }) => {
      if (highestNumber === "+") {
        return Math.min(lowestNumber, volume) === Number(lowestNumber)
      }
      return (
        Math.min(lowestNumber, volume) === Number(lowestNumber) &&
        Math.max(highestNumber, volume) === Number(highestNumber)
      )
    })?.price
    return price
  }

  return (
    <Layout extraClass="covid-testing" pageId={pageData.databaseId}>
      <Seo
        title={seoData.title}
        description={seoData.metaDesc}
        canonical={seoData.canonical}
        keywords={seoData.metaKeywords}
      />
      <BannerIntro
        imageUrl={pageData.covidTesting.bannerImage.localFile.childImageSharp.gatsbyImageData}
        altText={pageData.covidTesting.bannerImage.altText}
        headline={pageData.title}
        copy={pageData.covidTesting.copy}
      />
      <Divider />
      <section className="tabbed-content vertical p-0">
        <div className="container">
          <div className="row">
            <h2>{pageData.tabbedContent.tabbedContent.headline}</h2>
          </div>
          <div className="row">
            <Tabs>
              <TabList>
                {pageData.tabbedContent.tabbedContent.tab.map((type) => (
                  <Tab
                    onClick={() => {
                      setChecked(false)
                      setVolume(1)
                    }}
                  >
                    <div class="d-flex justify-content-between align-items-center grid-12">
                      <h4>{type.tabName}</h4>
                      <span class="small-text text-right">
                        Results in {type.resultsText}
                      </span>
                    </div>
                    <p>{type.tabCopy}</p>
                    <div class="link">Learn More</div>
                  </Tab>
                ))}
              </TabList>

              {pageData.tabbedContent.tabbedContent.tab.map((type) => (
                <TabPanel key={type}>
                  <div className="panel-header">
                    <div className="d-flex justify-content-between align-items-center grid-12">
                      <div className="d-flex justify-content-between align-items-center">
                        <h3>{type.tabName}</h3>
                        <span className="small-text">
                          Results in {type.resultsText}
                        </span>
                      </div>
                      <Button
                        link="/request-rates"
                        title={type.buttonName}
                        state={{ type: type.tabName }}
                      />
                    </div>

                    <p>{type.tabCopy}</p>
                  </div>
                  <div class="range-slider">
                    <h3>How many tests do you anticipate?</h3>
                    <p>Slide to view our bulk testing discounts.</p>
                    <RangeSlider value={volume} onChange={setVolume} />
                    <div className="tests d-flex align-items-center">
                      {type.testInfo &&
                        type.testInfo.map(({ testType }) => (
                          <div className="grid-6 text-center">
                            <h4>{testType.testName}</h4>
                            <div className="price">
                              {determinePrice(testType.pricing)}
                            </div>
                            <p>per test</p>
                          </div>
                        ))}
                    </div>
                  </div>
                  <p className="callout">{type.calloutCopy}</p>
                  <p className="disclaimer">{type.disclaimerText}</p>
                </TabPanel>
              ))}
            </Tabs>
          </div>
        </div>
      </section>
      <section className="two-third-one-third-image">
        <div className="container">
          <div className="row justify-content-center">
            <h2
              className="text-center"
              dangerouslySetInnerHTML={{
                __html:
                  pageData.twoThirdOneThirdImage.twoThirdOneThirdImage.headline,
              }}
            />
          </div>
          <div className="row">
            <div className="two-third-image">
            <GatsbyImage
                image={pageData.twoThirdOneThirdImage.twoThirdOneThirdImage.twoThirdImage.localFile.childImageSharp.gatsbyImageData}
                alt={pageData.twoThirdOneThirdImage.twoThirdOneThirdImage.twoThirdImage.altText}
            />
            </div>
            <div className="one-third-image">
            <GatsbyImage
                image={pageData.twoThirdOneThirdImage.twoThirdOneThirdImage.oneThirdImage.localFile.childImageSharp.gatsbyImageData}
                alt={pageData.twoThirdOneThirdImage.twoThirdOneThirdImage.oneThirdImage.altText}
            />
            </div>
          </div>
        </div>
      </section>
      <section className="full-panel-with-image bg-navy pt-0">
        <div className="container">
          <div className="row">
            <div className="grid-4 copy">
              <h2>{pageData.fullPanelWithImage.fullPanelWithImage.headline}</h2>
              <p>{pageData.fullPanelWithImage.fullPanelWithImage.copy}</p>
              <div class="d-flex align-items-center pt-3">
                <Button
                  link="/request-rates"
                  state={{
                    type: pageData.fullPanelWithImage.fullPanelWithImage
                      .headline,
                  }}
                  title={
                    pageData.fullPanelWithImage.fullPanelWithImage.buttonText
                  }
                />
                <span className="pl-1">
                  {pageData.fullPanelWithImage.fullPanelWithImage.priceText}
                </span>
              </div>
            </div>
            <div className="grid-7">
            <GatsbyImage
                image={pageData.fullPanelWithImage.fullPanelWithImage.image.localFile.childImageSharp.gatsbyImageData}
                alt={pageData.fullPanelWithImage.fullPanelWithImage.image.altText}
            />
            </div>
          </div>
        </div>
      </section>
      <section className="half-panels">
        <div className="container">
          <div className="row align-items-start">
            <div className="panel grid-6 text-center">
              <h2>{pageData.halfPanelPrices.halfPanelOfferings.headline}</h2>
              <p>{pageData.halfPanelPrices.halfPanelOfferings.copy}</p>
              <div className="d-flex pt-3">
                {pageData.halfPanelPrices.halfPanelOfferings.offering.map(
                  (offering) => (
                    <div className="grid-6">
                      <h3>{offering.headline}</h3>
                      <p className="price">{offering.price}</p>
                      <p>{offering.timeframe}</p>
                    </div>
                  )
                )}
              </div>
              <Button
                link="/request-rates"
                state={{ type: "sanitize" }}
                title={pageData.halfPanelPrices.halfPanelOfferings.buttonName}
              />
            </div>
            <div className="panel grid-6 text-center">
              <h2>{pageData.halfPanelBullets.halfPanelBullets.headline}</h2>
              <p>{pageData.halfPanelBullets.halfPanelBullets.copy}</p>
              <div
                dangerouslySetInnerHTML={{
                  __html: pageData.halfPanelBullets.halfPanelBullets.bullets,
                }}
              />
              <Button
                state={{
                  type: pageData.halfPanelBullets.halfPanelBullets.headline,
                }}
                link="/request-rates"
                title={pageData.halfPanelBullets.buttonName}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default CovidTesting

export const covidQuery = graphql`
  query covidTestingpageQuery {
    wpPage(databaseId: { eq: 309 }) {
      databaseId
      seo {
        title
        canonical
        metaDesc
        metaKeywords
      }
      title
      covidTesting {
        copy
        bannerImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      twoThirdOneThirdImage {
        twoThirdOneThirdImage {
          headline
          oneThirdImage {
            altText
            localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
          }
          twoThirdImage {
            altText
            localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
          }
        }
      }
      tabbedContent {
        tabbedContent {
          headline
          tab {
            testInfo {
              testType {
                pricing {
                  highestNumber
                  lowestNumber
                  price
                }
                testName
              }
            }
            buttonName
            calloutCopy
            disclaimerText
            resultsText
            tabCopy
            tabName
          }
        }
      }
      halfPanelPrices {
        halfPanelOfferings {
          buttonName
          copy
          headline
          offering {
            headline
            price
            timeframe
          }
        }
      }
      halfPanelBullets {
        buttonName
        halfPanelBullets {
          copy
          bullets
          headline
        }
      }
      fullPanelWithImage {
        fullPanelWithImage {
          buttonText
          copy
          hasPrice
          headline
          image {
            altText
            localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
          }
          priceText
        }
      }
    }
  }
`
